<template>
  <div class="blogCard mb-5">
    <a :href="blog.url" target="_blank">
      <div
        class="blogImage"
        :style="`background-image: url(${blog.image})`"
      ></div>
      <div class="blogDescript">
        <h3>
          {{ blog.title }}
        </h3>
        <p>
          {{ blog.description }}
        </p>
        <div>
          <img
            height="20"
            class="mr-2"
            src="/assets/images/svgs/imp-icon.svg"
            alt=""
          />
          <span>By Sterling</span>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ["blog"],
};
</script>
<style lang="scss">
.blogCard {
  background: #ffffff;
  box-shadow: 0px 50px 100px rgba(30, 30, 30, 0.05);
  border-radius: 20px;
  .blogImage {
    border-radius: 20px;
    height: 200px;
    background-size: cover !important;
    background-position: center !important;
  }
  .blogDescript {
    padding: 23px;
    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #255e13;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      color: rgba(18, 17, 19, 0.8);
    }
    div {
      span {
        font-weight: 500;
        font-size: 10px;
        color: #263238;
      }
    }
  }
}
</style>