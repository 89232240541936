<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="
            container
            shape-container
            d-flex
            align-items-center
            text-center-sm
          "
        >
          <div class="col px-0">
            <div class="row justify-content-center mb-5">
              <div class="col-md-10">
                <router-link
                  class="
                    btn btn-imp-secondary
                    bg-white
                    text-primary
                    border-primary
                    btn-icon
                    mt-2
                    py-3
                    px-5
                    router-link-exact-active router-link-active
                    ml-5 ml-none
                  "
                  to="/blogs"
                  >Go back</router-link
                >
              </div>
            </div>
            <div class="row align-items-center justify-content-center">
              <div class="col-md-5">
                <div class="text-center">
                  <img
                    class="headerimg headerimgblog img-fluid br-30"
                    src="/assets/images/blog-image.jpeg"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <h3
                  class="
                    text-dark
                    display-1
                    f-bold
                    text-head text-head-blog
                    mb-3
                  "
                >
                  Max Out Your Service Year: How To Earn, Save and Invest Money
                  During NYSC.
                </h3>
                <p class="f-16 text-dark mb-0">
                  GO is an app-based platform created to help you organise your
                  money on your mobile device…
                </p>
                <div class="mt-3">
                  <img
                    height="20"
                    class="mr-2"
                    src="/assets/images/svgs/imp-icon.svg"
                    alt=""
                  />
                  <span class="text-primary">By Tomiwa • August 12, 2019</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pb-2 mb-7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9 mx-auto">
            <div class="text-dark">
              <p>
                At Imperium, we believe everyone should enjoy clean, constant
                and reliable electricity without having to pay through their
                noses for it.
              </p>
              <p>
                To make this possible, we have partnered with reputable
                Renewable Energy organizations to deliver quality energy
                efficient systems that provide clean and steady electricity at
                your fingertips. Imperium also offers homes and businesses
                simple and affordable ways to pay for electricity using any of
                the innovative financing options available on the platform.
              </p>
              <p>
                At Imperium, we believe everyone should enjoy clean, constant
                and reliable electricity without having to pay through their
                noses for it.
              </p>
              <p>
                To make this possible, we have partnered with reputable
                Renewable Energy organizations to deliver quality energy
                efficient systems that provide clean and steady electricity at
                your fingertips. Imperium also offers homes and businesses
                simple and affordable ways to pay for electricity using any of
                the innovative financing options available on the platform.
              </p>
              <p>
                At Imperium, we believe everyone should enjoy clean, constant
                and reliable electricity without having to pay through their
                noses for it.
              </p>
              <p>
                To make this possible, we have partnered with reputable
                Renewable Energy organizations to deliver quality energy
                efficient systems that provide clean and steady electricity at
                your fingertips. Imperium also offers homes and businesses
                simple and affordable ways to pay for electricity using any of
                the innovative financing options available on the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pb-2 mb-7">
      <div class="container">
        <div class="evaluation">
          <h2 class="text-primary f-bold">Recommended Articles</h2>
          <div class="mt-5">
            <div class="row">
              <div class="col-md-4" v-for="(blog, index) in 3" :key="index">
                <BlogCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from "@/components/Blog/BlogCard";

export default {
  name: "Calculator",
  components: { BlogCard },
  data() {
    return {
      calculation: "general",
    };
  },
};
</script>

<style lang="scss">
.calHeading {
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 32.256px;
    color: #2b2b2b;
    display: inline-block;
    margin-bottom: 0px;
  }
}
.basedClass {
  background: #e0ecce;
  border-radius: 19px;
  display: inline-block;
  div {
    display: inline-block;
    font-weight: 500;
    font-size: 14.9652px;
    color: #255e13;
    padding: 7px 32px;
    line-height: 51px;
    cursor: pointer;
    &.active {
      background-color: #219653;
      border-radius: 19px;
      color: white;
    }
  }
}
.appliance {
  color: black;
  background: #0000000a;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 31px;
    padding: 1px 7px;
    cursor: pointer;
  }
  .name {
    margin: 0;
    font-weight: bold;
  }
  .descr {
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
  }
}
.bg-prp {
  background: #f5fcf2;
}
.text-head-blog {
  font-weight: 800;
  font-size: 2rem;
  line-height: 35px;
}
@media only screen and (max-width: 768px) {
  .headerimgblog {
    width: 100%;
    padding-top: 3px;
    margin-bottom: 35px;
  }
  .ml-none {
    margin-left: 0px !important;
  }
  .basedClass {
    margin-top: 20px;
  }
  .formView {
    margin-top: 0px !important;
    background: transparent;
  }
  .maintenn {
    margin-top: 45px;
  }
  .yourCal {
    padding: 0px;
    border: none;
  }
}
.evaluation .evaluateMedia p {
  font-weight: 300;
  font-size: 18px;
  color: #202020;
}
</style>